.bgBlur{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #6366F1;
    background-image: linear-gradient(160deg, #3b44e6 0%, #e8e8ee 100%);
    opacity: 0.5;
    width: 100%;
    height: 100%;
}
.sun{
    top:50px;
    animation:anim 20s linear alternate;
    box-shadow: 0px 1px 400px 42px rgba(255,255,255,0.75);
    -webkit-box-shadow: 0px 1px 400px 42px rgba(255,255,255,0.75);
    -moz-box-shadow: 0px 1px 400px 42px rgba(255,255,255,0.75);
}
@keyframes anim {
    0%{
        top:500px;
        box-shadow: 0px 1px 0px 2px rgba(255,255,255,0.75);
        -webkit-box-shadow: 0px 1px 0px 2px rgba(255,255,255,0.75);
        -moz-box-shadow: 0px 1px 0px 2px rgba(255,255,255,0.75);
    }
    20%{
        top:350px;
    }
    40%{
        top:250px;
        box-shadow: 0px 1px 74px 42px rgba(255,255,255,0.75);
        -webkit-box-shadow: 0px 1px 74px 42px rgba(255,255,255,0.75);
        -moz-box-shadow: 0px 1px 74px 42px rgba(255,255,255,0.75);
    }
    60%{
        top:100px;
    }
    80%{
        top:70px;
        box-shadow: 0px 1px 137px 42px rgba(255,255,255,0.75);
        -webkit-box-shadow: 0px 1px 137px 42px rgba(255,255,255,0.75);
        -moz-box-shadow: 0px 1px 137px 42px rgba(255,255,255,0.75);
    }
    100%{
        top:50px;
        box-shadow: 0px 1px 400px 42px rgba(255,255,255,0.75);
        -webkit-box-shadow: 0px 1px 400px 42px rgba(255,255,255,0.75);
        -moz-box-shadow: 0px 1px 400px 42px rgba(255,255,255,0.75);
    }
}