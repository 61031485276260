.my-bg{
    /*background: url('http://localhost:4006/static/media/banner4.de3369dc0ffd20c54a14.jpg');*/
    background-position: center;
}
.img1{
  animation:img1Animation 50s linear infinite;
}
.img2{
  animation:img2Animation 5s alternate-reverse infinite;
}
.img3{

}
@keyframes img1Animation {
  0%{
    rotate:0deg;
  }
  100%{
    rotate:360deg;
  }
}
@keyframes img2Animation {
  0%{
    scale:1;
  }
  100%{
    scale:1.1;
  }
}


.center>svg {
  width: 100%;
  height: 100%;
}
.center>svg .path {
  stroke-dasharray: 320;
  stroke-dashoffset: 0;
  animation: dash 1s linear;
}
.center>svg .text-animation {
  font-size: 10rem;
  fill: rgba(72, 138, 20, 0);
  stroke: darkblue;
  stroke-width: 3;
  animation: stroke 5s;
}
@keyframes dash {
  from {
    stroke-dashoffset: 320;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes stroke {
  0% {
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }
  100% {
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
  }
}
@keyframes blink {
  0% {
    width: 50px;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}