.cardWrap {
    width: 100%;
    margin: 3em 5px;
    color: #fff;
    font-family: sans-serif;
}
.card {
    /*background: linear-gradient(to bottom, #dd1e87 0%, #e50781 45%, #ecedef 26%, #ecedef 100%);*/
    /*background: linear-gradient(to bottom, #56d761 0%, #3eb24e 45%, #ecedef 26%, #ecedef 100%);*/
    background: linear-gradient(to bottom, #132d7f 0%, #1871cc 45%, #ffffff 26%, #ecedef 100%);
    height: 7rem;
    float: left;
    position: relative;
}

.cardLeft {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 12rem;
}

.cardRight {
    width: 3.5em;
    border-left: 0.18em dashed #fff;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.cardRight:before, .cardRight:after {
    content: "";
    position: absolute;
    display: block;
    width: 0.9em;
    height: 0.9em;
    background: #fff;
    border-radius: 50%;
    left: -0.5em;
}
.cardRight:before {
    top: -0.4em;
}
.cardRight:after {
    bottom: -0.4em;
}





.eye {
    position: relative;
    width: 2em;
    height: 1.5em;
    background: #fff;
    margin: 0 auto;
    border-radius: 1em/0.6em;
    z-index: 1;
}
.eye:before, .eye:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
}
.eye:before {
    width: 1em;
    height: 1em;
    background: #e84c3d;
    z-index: 2;
    left: 8px;
    top: 4px;
}
.eye:after {
    width: 0.5em;
    height: 0.5em;
    background: #fff;
    z-index: 3;
    left: 12px;
    top: 8px;
}

.number {
    text-align: center;
    text-transform: uppercase;
}
.number h3 {
    color: #e84c3d;
    margin: 0.9em 0 0 0;
    font-size: 2.5em;
}
.number span {
    display: block;
    color: #a2aeae;
}

.barcode {
    height: 2em;
    width: 0;
    margin: 1.2em 0 0 0.8em;
    box-shadow: 1px 0 0 1px #343434, 5px 0 0 1px #343434, 10px 0 0 1px #343434, 11px 0 0 1px #343434, 15px 0 0 1px #343434, 18px 0 0 1px #343434, 22px 0 0 1px #343434, 23px 0 0 1px #343434, 26px 0 0 1px #343434, 30px 0 0 1px #343434, 35px 0 0 1px #343434, 37px 0 0 1px #343434, 41px 0 0 1px #343434, 44px 0 0 1px #343434, 47px 0 0 1px #343434, 51px 0 0 1px #343434, 56px 0 0 1px #343434, 59px 0 0 1px #343434, 64px 0 0 1px #343434, 68px 0 0 1px #343434, 72px 0 0 1px #343434, 74px 0 0 1px #343434, 77px 0 0 1px #343434, 81px 0 0 1px #343434;
}