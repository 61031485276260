/*@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Qwitcher+Grypen:wght@400;700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sevillana&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap');
.raleway {
    font-family: "Raleway", sans-serif;
}
.dance{
    font-family: 'Dancing Script', cursive;
}
.seli{
    font-family: 'Sevillana', cursive;
}
.phil{
    font-family: 'Philosopher', sans-serif;
}

table thead tr th {
    text-align: start;
}

.heart-react {
    animation: anim 1s linear forwards;
}
.p-divider.p-component.p-divider-horizontal.p-divider-solid.p-divider-left {
    margin: 7px 0 !important;
}
@keyframes anim {
    0% {
        scale: 1.0;
    }
    50% {
        scale: 1.1;
    }
    100% {
        scale: 1.0;
    }
}


html, body {
    overflow-x: hidden;
}

.loginClipping {
    clip-path: polygon(100% 0, 100% 25%, 50% 50%, 0 25%, 0 0);
}

/*swiper css*/

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    font-size: 18px;
    /*background: #fff;*/
    display: flex;
    justify-content: center;
    align-items: center;
}

.spin360{
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0%{
        rotate: 0deg;
    }
    100%{
        rotate: 360deg;
    }
}